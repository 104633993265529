<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :search="true" @search="search"
      @selection-change="handleSelectionChange" class="class-136136234">
      <template slot="toolbar">
        <div class="toolbar-box">
          <el-form-item label="注册时间" class="col">
            <el-date-picker size="small" style="width: 180px; margin-right: 20px" v-model="registerTime" type="date"
              placeholder="选择日期" @change="registerTimeChange" clearable></el-date-picker>
          </el-form-item>
          <el-form-item label="企业等级" class="col">
            <el-select size="small" style="width: 180px; margin-bottom: 4px" v-model="par.level_id"
              placeholder="请选择企业等级">
              <el-option v-for="item in levelList" :key="item.id" :label="item.level_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <div>
            <el-select size="small" class="keyword-select" v-model="keyword">
              <el-option v-for="item in keywordList" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <el-input class="keyword-input" size="small" v-model="keywordValue" placeholder="请输入关键词进行搜索"
              clearable></el-input>
          </div>
          <div class="col-auto ml-2">
            <el-button @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </div>
        <div class="button-list">
          <el-button type="primary" @click="addShop" size="small">添加企业
          </el-button>
          <el-button type="primary" @click="allHandel('启用')" size="small">批量启用
          </el-button>
          <el-button type="primary" @click="allHandel('停用')" size="small">批量停用
          </el-button>
          <el-button type="primary" @click="allHandel('删除')" size="small">批量删除
          </el-button>
          <el-button type="primary" @click="senMessage" size="small">发送站内信
          </el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column type="selection" width="50" />

        <el-table-column prop="shop_id" label="企业ID" width="65" :show-overflow-tooltip="true" />

        <el-table-column prop="uname" label="企业账号" width="110" :show-overflow-tooltip="true" />

        <el-table-column prop="shop_name" label="企业名称" min-width="180" :show-overflow-tooltip="true" />

        <el-table-column prop="salesperson" label="销售员" show-overflow-tooltip width="80" />

        <el-table-column prop="shop_consum_price" label="消费金额" width="120" />

        <el-table-column prop="balance_account" label="账户余额" width="120" />

        <el-table-column prop="level_duetime" label="剩余时间" :formatter="level_duetimeFormatter" width="80" />

        <el-table-column prop="level_name" label="企业等级" width="96" show-overflow-tooltip />

        <el-table-column prop="has_shop_num" label="已建套餐卡商城数" width="80">
          <template slot-scope="scope">
            <span class="has_shop_num" @click="handleClickShop(scope.row, 4)">
              {{ scope.row.has_shop_combo_num }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="has_new_shop_combo_num" label="已建智能套餐卡商城数" width="100">
          <template slot-scope="scope">
            <span class="has_shop_num" @click="handleClickShop(scope.row, 5)">
              {{ scope.row.has_new_shop_combo_num }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="has_shop_num" label="已建双选商城数" width="80">
          <template slot-scope="scope">
            <span class="has_shop_num" @click="handleClickShop(scope.row, 6)">
              {{ scope.row.has_sx_shop_num }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="has_shop_num" label="已建现金商城数" width="80">
          <template slot-scope="scope">
            <span class="has_shop_num" @click="handleClickShop(scope.row, 1)">
              {{ scope.row.has_shop_num }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="启用状态" width="80">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.shop_disable" active-color="#2155d5" inactive-color="#ff4949"
              @change="switchChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <!--品牌名称-->
        <!--<el-table-column prop="brand_name" label="品牌名称"/>-->
        <!--操作-->
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-button size="mini" type="primary" @click="edit(scope.row.shop_id)">编辑
              </el-button>
              <!-- <el-button
                size="mini"
                type="primary"
                @click="rechargeHandle(scope.row)"
              >充值
              </el-button> -->
              <el-button size="mini" type="primary" @click="openEnterRulesProxy(scope.row)">权限
              </el-button>
              <el-button size="mini" type="primary" @click="passwordHandle(scope.row)">重置密码
              </el-button>
              <el-button size="mini" type="danger" @click="handleDelGoodsAudit([scope.row.shop_id])">删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size" :layout="MixinTableLayout" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <el-dialog-x-dialog title="发送站内信" :visible.sync="senMessageBool">
      <el-form :model="mesForm" :rules="rules" ref="mesForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="活动名称" prop="title">
          <el-input v-model="mesForm.title"></el-input>
        </el-form-item>
        <el-form-item label="活动形式" prop="notice_content">
          <el-input type="textarea" v-model="mesForm.notice_content" :autosize="{ minRows: 2 }" maxlength="200"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button @click="cancel" size="small">取消
          </el-button>
          <el-button type="primary" @click="send('mesForm')" size="small">确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>

    <!-- 企业站点弹窗-->
    <x-dialog title="企业站点信息" class="ep-info" :proxy="dialogVisible">
      <en-table-layout :tableData="shop_list.data" :loading="loading1" :toolbar="false" style="max-height: 60vh">
        <template slot="table-columns">
          <el-table-column prop="shop_id" label="编号" />
          <el-table-column prop="qrcode" label="站点商城二维码" width="140" align="center">
            <template slot-scope="scope">
              <el-popover placement="right" trigger="hover">
                <img :src="scope.row.qrcode" class="goods-cover" alt="" />
                <img :src="scope.row.qrcode" :title="scope.row.qrcode" slot="reference"
                  style="width: 70px; height: 70px" alt="" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="shop_name" label="站点名称" show-overflow-tooltip />
          <el-table-column prop="domain_name" label="站点域名" min-width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.domain_name + $url }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="balance_account" label="站点余额">
            <template slot-scope="scope">
              <span>{{ scope.row.balance_account | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
        </template>
        <el-pagination background slot="pagination" @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1" :current-page="shop_list.page_no" :page-sizes="MixinPageSizes"
          :page-size="shop_list.page_size" :layout="MixinTableLayout" :total="shop_list.data_total"></el-pagination>
      </en-table-layout>
    </x-dialog>

    <!--    企业套餐卡商城弹窗-->
    <x-dialog title="企业套餐卡商城信息" :proxy="comboVisible">
      <en-table-layout :tableData="shop_list.data" :loading="loading1" style="max-height: calc(80vh - 100px)">
        <template slot="toolbar">
          <el-form :model="shop_params" style="width: 60%; display: flex; margin: auto;">
            <el-input v-model="shop_params.shop_name" style="margin-right: 36px;"></el-input>
            <el-button type="primary" @click="GET_ComShop">搜索</el-button>
          </el-form>
        </template>
        <template slot="table-columns">
          <el-table-column prop="shop_id" label="编号" />
          <el-table-column prop="shop_name" label="套餐卡商城名称" />
          <el-table-column prop="shop_name" label="商城类型">
            <template slot-scope="{row}">
              <span>{{ row.shop_type == 5 ? 'H5单次卡商城' : '小程序商城' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="balance_account" label="站点余额">
            <template slot-scope="scope">
              <span>{{ scope.row.balance_account | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
        </template>
        <el-pagination background slot="pagination" @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1" :current-page="shop_list.page_no" :page-sizes="MixinPageSizes"
          :page-size="shop_list.page_size" :layout="MixinTableLayout" :total="shop_list.data_total"></el-pagination>
      </en-table-layout>
    </x-dialog>
    <!--    充值弹窗-->
    <el-dialog-x-dialog title="充值操作" :visible.sync="rechargeVisible" width="35%">
      <el-form :model="rechargeForm" :rules="rechargeRules" ref="rechargeRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="企业名称" prop="name">
          <span v-text="rechargeForm.shop_name"></span>
        </el-form-item>
        <el-form-item label="充值金额" prop="money">
          <el-input v-model="rechargeForm.money" placeholder="请输入要充值的金额"></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
          <el-input type="textarea" v-model="rechargeForm.remark" placeholder="请输入备注信息，不超过100字。"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: space-between;padding-bottom: 22px">
        <span></span>
        <span>
          <el-button size="small" @click="rechargeVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="rechargeConfirm('rechargeRef')">确 定</el-button>
        </span>
      </div>
    </el-dialog-x-dialog>
    <!--    权限划分-->
    <x-dialog :proxy="enterRulesProxy">
      <el-form :model="enterRulesForm" label-position="top">
        <el-form-item label="企业特殊权限设置">
          <table border>
            <tr>
              <th width="20%">权限开关</th>
              <th width="25%">权限名称</th>
              <th width="55%">描述信息</th>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.open" />
              </td>
              <td>
                销售开卡功能
              </td>
              <td>
                开启后，形成卡券销售订单，对卡券销售统一管理，提高开卡效率，和卡券财务对账等
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.supplier_name_open" />
              </td>
              <td>
                显示商品所属供应商
              </td>
              <td>
                开启后，将在企业端，各站点端的商品列表、商品选择器中展示该商品所属的供应商名称
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.force_exchange_goods_open" />
              </td>
              <td>
                智能换货功能
              </td>
              <td>
                开启后，企业跳转套餐卡商城后台、双选商城后台时，可进行套餐礼包内商品智能换货规则的设置
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.double_choice_mall_open" />
              </td>
              <td>双选商城功能</td>
              <td>
                开启后，企业可创建双选商城
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.drop_shipping_mall_open" />
              </td>
              <td>一件代发商城功能</td>
              <td>
                开启后，企业可创建一件代发商城
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.auto_up_down_status" />
              </td>
              <td>智能运营功能</td>
              <td>
                开启后，企业跳转现金商城后台、双选商城后台时，可进行京东商品自动上下架规则的设置
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.storey_decorate20_status" />
              </td>
              <td>楼层装修2.0功能</td>
              <td>
                开启后，现金商城后台在进行楼层装修时，可进行公告、富文本、电梯、魔方、热区、背景色模块的设置
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.has_self_goods" />
              </td>
              <td>企业自有产品功能</td>
              <td>
                开启后，企业可添加自己的商品供站点商城使用。（注：开启后，如果该企业已经添加自有商品，这里不允许再关闭该功能）
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.counting_card_open" />
              </td>
              <td>小程序商城功能</td>
              <td>
                开启后，企业在智能套餐卡商城应用中可创建小程序商城。
              </td>
            </tr>
            <tr>
              <td>
                <el-switch v-model="enterRulesForm.shop_self_goods_open" />
              </td>
              <td>商城自有商品功能</td>
              <td>
                开启后，企业下双选商城可拥有添加商城自有商品功能。
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!--重置密码弹窗-->
    <el-dialog-x-dialog title="重置密码" :visible.sync="passwordVisible" width="600px">
      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="新密码" prop="password">
          <el-input style="width:400px;" type="password" v-model="passwordForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="endPwd">
          <el-input style="width:400px;" type="password" v-model="passwordForm.endPwd" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: space-between;padding-bottom: 22px">
        <span></span>
        <span>
          <el-button size="small" @click="passwordVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="passwordConfirm('passwordRef')">确 定</el-button>
        </span>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>
<script>
import * as API_shop from "@/api/shop";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import xDialog from '@/components/x-dialog/x-dialog'
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { validateUname } from "@/utils/validate";

export default {
  name: "enterplist",
  components: { EnTableLayout, xDialog },
  data () {
    const validatePwd = (rule, value, callback) => {
      if (!validateUname(value)) {
        callback(new Error('请输入密码, 6~20位纯数字或纯字母或数字+字母组成。字母区分大小写'))
      } else {
        callback()
      }
    };
    const validateNewPwd = (rule, value, callback) => {
      if (value !== this.passwordForm.password) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    return {
      // 重置密码表单
      passwordForm: {
        password: "",
        endPwd: ""
      },
      // 重置密码
      passwordVisible: false,
      // 重置密码校验
      passwordRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, validator: validatePwd, trigger: "blur" },
        ],
        endPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validateNewPwd, trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
          },
        ],
      },
      clickStatus: true,
      senMessageBool: false,
      keywordList: [
        { label: "企业名称", value: 1 },
        { label: "企业账号", value: 2 },
        { label: "销售员", value: 3 },
      ],
      keyword: 1,
      keywordValue: '',
      searchForm: {},
      value: "",
      idsList: [],
      registerTime: "",
      // 列表loading状态
      loading: false,
      rechargeVisible: false,
      rechargeForm: {
        shop_id: '',
        shop_name: '',
        money: '',
        remark: ''
      },
      rechargeRules: {
        money: [
          { required: true, message: '充值金额不能为空' }
        ],
        remark: [
          { max: 100, message: '长度在不超过 100 个字符' }
        ]
      },
      // 列表参数
      mesForm: {
        notice_content: "",
        title: "",
        shop_ids: "",
        shop_type: 3,
      },
      params: {
        page_no: 1,
        page_size: 20,
        shop_type: 3,
      },
      par: {
        member_name: "",  // 企业账号
        shop_name: "",  // 企业名称
        start_time: "",
        end_time: "",
        salesperson: "", // 销售员
        level_id: "",
      },
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        message: "",
        pass: 1,
      },
      rules: {
        title: { required: true, message: "请输入标题", trigger: "blur" },
        notice_content: {
          required: true,
          message: "请输入内容",
          trigger: "blur",
        },
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        message: [
          { required: false, message: "请输入审核备注！", trigger: "blur" },
        ],
      },
      // 审核商品 dialog
      dialogGoodsAuditVisible: false,
      // 审核商品 dialogTitle
      dialogGoodsAuditTitle: "审核商品",
      // 高级搜索数据
      advancedForm: {
        goods_name: "",
        goods_sn: "",
        shop_name: "",
        category_path: "",
      },
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],
      importLoading: false,
      levelList: [],

      loading1: true,
      shop_list: "",
      shop_params: {
        page_no: 1,
        page_size: 20,
        parent_id: "", //企业id
        shop_type: ""
      },
      enterRulesProxy: $xDialog.create({
        title: '权限划分',
        width: '35vw',
        wrapperClass: 'enter-rules-wrapper',
        beforeConfirm: () => this.enterRulesConfirm(),
        beforeCancel: () => this.enterRulesProxy.dismiss()
      }),
      comboVisible: $xDialog.create({
        title: '企业套餐卡商城信息',
        width: '60vw',
        wrapperClass: 'enter-rules-wrapper',
        disableConfirm: true,
        disableCancel: true,
        isScroll: false
      }),
      dialogVisible: $xDialog.create({
        title: '企业站点信息',
        width: '60vw',
        wrapperClass: 'enter-rules-wrapper',
        disableConfirm: true,
        disableCancel: true,
        isScroll: false
      }),
      enterRulesForm: {
        shop_id: '',
        open: false,
        supplier_name_open: false,
        force_exchange_goods_open: false,
        double_choice_mall_open: true,
        drop_shipping_mall_open: false,
        auto_up_down_status: false,
        storey_decorate20_status: false,
        has_self_goods: true,
        counting_card_open: false,
        shop_self_goods_open: false,
      }
    };
  },
  mounted () {
    this.unixToDate = Foundation.unixToDate;
    this.GET_GoodsAuditList();
    this.GET_levelList();
  },
  activated () {
    this.GET_levelList();
    this.GET_GoodsAuditList();
  },
  watch: {
    'keyword': function () {
      this.par.member_name = ''
      this.par.shop_name = ''
      this.par.salesperson = ''
      this.keywordValue = ''
    },
    'keywordValue': function (newValue) {
      this.par.member_name = ""
      this.par.shop_name = ""
      this.par.salesperson = ""
      switch (this.keyword) {
        case 1:
          this.par.shop_name = newValue
          break;
        case 2:
          this.par.member_name = newValue
          break;
        case 3:
          this.par.salesperson = newValue
          break;
      }
    },
    'rechargeForm.money': function (newValue) {
      if (newValue !== '-' && newValue !== '-0' && newValue !== '0.0' && newValue !== '-0.0') {
        if (newValue.length > 1 && newValue[newValue.length - 1] === '.' && newValue.split('.').length === 2) return;
        const num = parseInt(parseFloat(newValue) * 100) / 100
        if (num || num === 0) {
          let data = (newValue + '').split('.')
          const bool = data.length === 2
          if (bool && data[1] === '0') this.rechargeForm.money = num + '.0'
          else if (bool && data[1][1] === '0') {
            if ((num + '').split('.').length === 2) this.rechargeForm.money = num + '0'
            else if (data[1][0] === '0') this.rechargeForm.money = num + '.00'
          } else this.rechargeForm.money = num
        } else this.rechargeForm.money = ''
      }
    }
  },
  methods: {
    // parent_id=企业id  mall_type=商城类型4.积分商场;1现金商城;5智能套餐卡
    handleClickShop (row, type) {
      this.loading1 = true;
      this.shop_params.parent_id = row.shop_id;
      this.shop_params.shop_type = type
      if (type === 4 || type === 5) {
        this.comboVisible.display()
        this.GET_ComShop()
      } else if (type === 1) {// 现金商城
        this.dialogVisible.display()
        this.Get_ShopChilds();
      } else if (type === 6) {// 双选商城数
        this.dialogVisible.display()
        this.Get_ShopChilds();
      }
    },
    Get_ShopChilds () {// 现金商城、双选商城
      if (this.shop_params.shop_name) {
        delete this.shop_params.shop_name
      }
      API_shop.getShopChilds(this.shop_params).then((res) => {
        this.loading1 = false;
        this.shop_list = res;
      });
    },
    GET_ComShop () {//套餐卡、AI智能套餐卡
      API_shop.getShopChilds(this.shop_params).then((res) => {
        this.loading1 = false;
        this.shop_list = res;
      });
    },
    handlePageSizeChange1 (size) {
      this.shop_params.page_size = size;
      this.Get_ShopChilds();
    },
    handlePageCurrentChange1 (page) {
      this.shop_params.page_no = page;
      this.Get_ShopChilds();
    },
    // 格式剩余天数
    level_duetimeFormatter (row) {
      var date1 = new Date(); //开始时间
      var date3 = row.level_duetime * 1000 - date1.getTime(); //时间差的毫秒数zhi
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      if (days < 0) return '0天'
      return days + "天";
    },
    switchChange (item) {
      let params = {
        shop_ids: [item.shop_id],
        disabled: 0,
      };

      if (!item.shop_disable) {
        params.disabled = -1;
      }
      this.openConmpany([item.shop_id], params);
    },
    cancel () {
      this.senMessageBool = false;
    },
    send (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.idsList.length > 0) {
            this.mesForm.shop_ids = this.idsList;
            API_shop.sendMessage(this.mesForm).then((res) => {
              this.$message({
                showClose: true,
                message: "发送成功",
                type: "success",
              });
              this.senMessageBool = false;
            });
          } else {
            this.$message.error("请勾选企业");
            return;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    senMessage () {
      if (this.idsList.length == 0) {
        this.$message.error("请勾选企业");
        return;
      }
      this.senMessageBool = true;
    },
    edit (id) {
      this.$router.push({
        path: "/enterpadd",
        query: {
          shopid: id,
        },
      });
    },
    passwordHandle (data) {
      console.log(data)
      this.passwordForm.password = ""
      this.passwordForm.endPwd = ""
      this.passwordForm.shop_id = data.shop_id
      this.passwordVisible = true
    },
    passwordConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_shop.editEnterprisePassword(this.passwordForm.shop_id, { password: this.passwordForm.password }).then(() => {
            this.$message.success('修改成功')
            this.passwordVisible = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    rechargeHandle (data) {
      this.rechargeForm.shop_id = data.shop_id
      this.rechargeForm.shop_name = data.shop_name
      this.rechargeForm.money = ''
      this.rechargeForm.remark = ''
      this.rechargeVisible = true
    },
    rechargeConfirm (formName) {
      if (!this.clickStatus) return this.$message.warning('请勿重复提交充值');
      this.clickStatus = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // API_shop.noRepetition().then((token) => {  // 防止重复提交
          //   API_shop.recharge(this.rechargeForm).then(() => {
          //     this.$message.success('充值成功')
          //     this.rechargeVisible=false
          //     this.GET_GoodsAuditList()
          //   })
          // })
          API_shop.recharge(this.rechargeForm).then(() => {
            this.$message.success('充值成功')
            this.rechargeVisible = false
            this.GET_GoodsAuditList()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      setTimeout(() => {
        this.clickStatus = true
      }, 2000)
    },
    openConmpany (shop_ids, params) {
      API_shop.openCompany(shop_ids, params).then((res) => {
        this.$message({
          showClose: true,
          message: res.message,
          type: "success",
        });
        this.GET_GoodsAuditList();
      });
    },
    allHandel (type) {
      if (this.idsList.length == 0) {
        this.$message.error("请勾选企业");
        return;
      }
      this.$confirm("确认批量操作这些企业, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        switch (type) {
          case '启用':
            let params = {
              shop_ids: this.idsList,
              disabled: 0,
            };
            this.openConmpany(this.idsList, params);
            break;
          case '停用':
            let params1 = {
              shop_ids: this.idsList,
              disabled: -1,
            };
            this.openConmpany(this.idsList, params1);
            break;
          case '删除':
            console.log("批量删除");
            API_shop.deleteCompany(this.idsList).then((res) => {
              this.$message({
                showClose: true,
                message: "删除成功",
                type: "success",
              });
              this.GET_GoodsAuditList();
              console.log(res);
            });
            break;
          default:
            console.log("误操作");
        }
      });
    },
    handleDelGoodsAudit (list) {
      this.$confirm("确认删除这些企业, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        API_shop.deleteCompany(list).then((res) => {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.GET_GoodsAuditList();
          console.log(res);
        });
      });
    },
    addShop () {
      this.$router.push({
        path: "/enterpadd",
      });
    },
    search () {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    registerTimeChange () {
      if (!this.registerTime) {
        this.par.start_time = "";
        this.par.end_time = "";
        return;
      }
      this.par.start_time = new Date(this.registerTime).getTime() / 1000;
      this.par.end_time = new Date(this.registerTime).getTime() / 1000;
    },
    GET_levelList () {
      API_shop.getLevelList().then((res) => {
        this.levelList = res;
        this.levelList.unshift({ level_name: '全部', id: '' })
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 获取平台下企业列表 */
    GET_GoodsAuditList () {
      this.loading = true;
      let npar = {
        ...this.params,
        ...this.par,
      };
      API_shop.getShopList(npar)
        .then((response) => {
          this.loading = false;
          if (response.page_no !== 1 && response.data.length === 0) {
            this.params.page_no = response.page_no -= 1
            this.GET_GoodsAuditList();
          } else {
            this.tableData = response;
            let temp = response.data;
            for (let i = 0; i < response.data.length; i++) {
              // temp[i].memberDisbled = temp[i].memberDisbled == 0 ? true : false;
              temp[i].shop_disable = temp[i].shop_disable === 'OPEN';
            }
          }
        })
        .catch(() => (this.loading = false));
    },
    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.shop_id);
      });
      this.idsList = ids;
    },
    // 权限划分查看
    openEnterRulesProxy (item) {
      API_shop.getEnterRules({ shop_id: item.shop_id }).then(res => {
        this.enterRulesForm['shop_id'] = item.shop_id
        this.enterRulesForm['open'] = res.open === 'OPEN'
        this.enterRulesForm['supplier_name_open'] = res.supplier_name_open === 'OPEN'
        this.enterRulesForm['force_exchange_goods_open'] = res.force_exchange_goods_open === 'OPEN'
        this.enterRulesForm['double_choice_mall_open'] = res.double_choice_mall_open === 'OPEN'
        this.enterRulesForm['drop_shipping_mall_open'] = res.drop_shipping_mall_open === 'OPEN'
        this.enterRulesForm['counting_card_open'] = res.counting_card_open === 'OPEN'//几次卡商城权限
        this.enterRulesForm['shop_self_goods_open'] = res.shop_self_goods_open === 'OPEN'//商城自有商品功能

        this.enterRulesForm['auto_up_down_status'] = res.auto_up_down_status === 1
        this.enterRulesForm['storey_decorate20_status'] = res.storey_decorate20_status === 1
        this.enterRulesForm['has_self_goods'] = res.has_self_goods === 1
        this.enterRulesProxy.display()
      })
    },
    //提交保存权限
    enterRulesConfirm () {
      let enterRulesForm = {
        ...this.enterRulesForm
      }
      enterRulesForm.open = enterRulesForm.open ? 'OPEN' : 'CLOSE';
      enterRulesForm.supplier_name_open = enterRulesForm.supplier_name_open ? 'OPEN' : 'CLOSE';
      enterRulesForm.force_exchange_goods_open = enterRulesForm.force_exchange_goods_open ? 'OPEN' : 'CLOSE';
      enterRulesForm.double_choice_mall_open = enterRulesForm.double_choice_mall_open ? 'OPEN' : 'CLOSE';
      enterRulesForm.drop_shipping_mall_open = enterRulesForm.drop_shipping_mall_open ? 'OPEN' : 'CLOSE';

      enterRulesForm.counting_card_open = enterRulesForm.counting_card_open ? 'OPEN' : 'CLOSE';
      enterRulesForm.shop_self_goods_open = enterRulesForm.shop_self_goods_open ? 'OPEN' : 'CLOSE';//商城自有商品功能
      enterRulesForm.auto_up_down_status = enterRulesForm.auto_up_down_status ? 1 : 0;
      enterRulesForm.storey_decorate20_status = enterRulesForm.storey_decorate20_status ? 1 : 0;
      enterRulesForm.has_self_goods = enterRulesForm.has_self_goods ? 1 : 0;
      API_shop.editEnterRules(enterRulesForm).then(res => {
        this.$message.success(res.message)
        this.enterRulesProxy.dismiss()
      })
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 0;
  max-height: calc(80vh);
  overflow-y: auto;
}

/deep/.el-scrollbar__wrap {
  margin-bottom: 0 !important;
  // margin-right: 0 !important;
  // padding: 0;
}

/deep/.class-136136234 {
  .en-table-layout-toolbar {
    padding: 0;
  }
}

.toolbar-box {
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;

  /deep/ .keyword-select {
    width: 105px;

    input {
      background-color: #E6ECF7;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  /deep/ .keyword-input {
    width: 180px;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.button-list {
  padding: 4px 15px;
}

.has_shop_num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.has_shop_num:hover {
  text-decoration: underline;
}

.ep-info ::v-deep {
  .el-table__row {
    td {
      padding: 0;
    }
  }
}

::v-deep .enter-rules-wrapper {
  .el-form-item {
    .el-form-item__label::after {
      right: -6px;
      top: 39%;
    }
  }

  table {
    border: 1px solid #aeb3bbdb;

    tr>th {
      text-align: center;
      background-color: #E6ECF7;
    }

    //tr > th:nth-child(1){
    //  width: 10%;
    //}
    //tr > th:nth-child(2){
    //  width: 18%;
    //}

    tr>td {
      text-align: center;
    }

    tr>td:last-child {
      text-align: left;
      line-height: 20px;
    }
  }
}

::v-deep .x-dialog {
  max-height: 94vh;
  min-width: 50vw;
}
</style>
